/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled, Text } from "theme-ui"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Slider from "react-slick"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import { Hero } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Layout,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  ColorBar,
  HighlightedFacilitiesSideNav,
  HeroGradient,
  PrevArrow,
  NextArrow,
  SectionHeading,
  VideoPlayer,
} from "../components/elements"

const HighlightedFacility = ({ data, pageContext }) => {
  const page = data.datoCmsHighlightedFacility
  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  }
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />

      <Hero>
        <BackgroundImage Tag="div" fluid={page.heroImage.fluid}>
          <HeroGradient>
            <Container sx={{ px: "1em", py: [6, 6, 7] }}>
              <Styled.h1>{page.title}</Styled.h1>
              <Breadcrumb
                crumbs={crumbs}
                crumbSeparator=" / "
                crumbLabel={page.title}
              />
            </Container>
          </HeroGradient>
          <ColorBar sx={{ position: "absolute", bottom: 0, left: 0 }} />
        </BackgroundImage>
      </Hero>

      <Container sx={{ px: "1em", py: 5 }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Box
            as="main"
            sx={{
              flexGrow: 99999,
              flexBasis: 0,
              minWidth: 320,
            }}
          >
            {/* Content */}
            <Box as="section" id="content">
              <Flex flexWrap="wrap" justifyContent="center">
                <Box sx={{ width: ["full"], pr: [0, 0, 4] }}>
                  <Styled.h6 as="p">{page.introContent}</Styled.h6>

                  {page.video && (
                    <Box sx={{ my: 4 }}>
                      <VideoPlayer url={page.video.url} />
                    </Box>
                  )}

                  <Text
                    dangerouslySetInnerHTML={{
                      __html: page.contentNode.childMarkdownRemark.html,
                    }}
                  />

                  {page.images.length > 0 ? (
                    <Section id="gallery">
                      <Container sx={{ px: "1em" }}>
                        <Flex
                          sx={{
                            flexWrap: "wrap",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <Box sx={{ width: ["full"] }}>
                            <SectionHeading>Photo Gallery</SectionHeading>
                            <Slider {...settings} sx={{ color: "white" }}>
                              {page.images &&
                                page.images.map(img => (
                                  <img
                                    src={img.url}
                                    alt="Facilities"
                                    key={img.url}
                                  />
                                ))}
                            </Slider>
                          </Box>
                        </Flex>
                      </Container>
                    </Section>
                  ) : null}
                </Box>
              </Flex>
            </Box>
          </Box>

          {/* Sidebar */}
          <HighlightedFacilitiesSideNav />
        </Box>
      </Container>
    </Layout>
  )
}

export default HighlightedFacility

export const query = graphql`
  query highlightedFacilityPageQuery($slug: String!) {
    datoCmsHighlightedFacility(slug: { eq: $slug }) {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      introContent
      id
      position
      slug
      title
      heroImage {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      meta {
        status
      }
      images {
        url
        fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      video {
        url
      }
      seoMetaTags {
        tags
      }
    }
  }
`
